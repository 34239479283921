import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists } from '@/filters/fromDict'
// import { formatDate } from '@/utils/auth/common'

import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
// 查询form contract-file-manage-list 合同文件列表
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, trigger: 'blur' }],
      attrs: {
        clearable: true,
        maxlength: '20',
        placeholder: '请输入'
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        clearable: true,
        placeholder: '请选择',
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: SupplierSelect
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      attrs: {
        clearable: true,
        placeholder: '请选择',
        options: content.reviewOptions,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect
    },
    {
      label: '签约状态',
      prop: 'status',
      attrs: {
        clearable: true,
        placeholder: '请选择',
        options: content.signStatusOptions,
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      isHidden: content.fold
    },
    {
      label: '发起签署日期',
      prop: 'signDate',
      attrs: {
        placeholder: '选择日期',
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期',
        'value-format': 'timestamp'
      },
      tag: 'el-date-picker',
      isHidden: content.fold,
      on: {
        change (data) {
          console.log(data, 'data22222')
          content.changeSignDate(data)
        }
      }
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeStatus',
      attrs: {
        clearable: true,
        placeholder: '请选择',
        options: getDictLists('FILE_MAKE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      isHidden: content.fold
    }
  ]
}
// 表格form contract-file-manage-list 合同文件列表
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80'
    },
    {
      label: '业务编号',
      prop: 'businessNo'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left'
    },
    {
      label: '合同任务发起方式',
      prop: 'createType',
      formatter: row => {
        return row.createType === '0' ? '合同流程发起' : '用户自行发起'
      }
    },
    {
      label: '任务发起时间',
      prop: 'addTime'
      // formatter: row => {
      //   return row.queryTaskResultDTO?.createTime ? formatDate(row.queryTaskResultDTO?.createTime, 'YY-MM-DD hh:mm:ss') : '--'
      // }
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName'
    },
    {
      label: '审核状态',
      prop: 'reviewStatusName',
      formatter: row => {
        return (row.reviewStatusName ? row.reviewStatusName : '--')
      }
    },
    {
      label: '签约状态',
      prop: 'statusName'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 详情  contract-file-manage-info 合同审核详情
export const infoForm = (content) => {
  const span = 5
  return [
    {
      label: '文件来源：',
      prop: 'businessNo',
      span
    },
    {
      label: '决议时间：',
      prop: 'gysId',
      span
    },
    {
      label: '决议地点：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '文件生成时间：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '保理服务合同编号：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '应到股东数(人)：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '实到股东数(人)：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '保理服务合同签署证人：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '文件签署时间：',
      prop: 'reviewStatus',
      span
    }
  ]
}
// 选择业务单号
export const fundForm = (content) => {
  return [
    {
      label: '供应商',
      prop: 'gysId',
      rules: [{ required: false, message: '请选择供应商', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择供应商',
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: SupplierSelect,
      span: 24,
      on: {
        change (data) {
          content.changeGys(data)
        }
      }
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: true, message: '请选择业务编号', trigger: 'change' }],
      attrs: {
        filterable: true,
        clearable: true,
        maxlength: '20',
        placeholder: '请选择业务编号',
        options: content.businessOption,
        selectedField: ['businessNo', 'businessNo'],
        disabled: !content.addQueryParas.gysId
      },
      span: 24,
      tag: BaseSelect,
      on: {
        change (data) {
          content.changeBusinessNo(data)
        }
      }
    },
    {
      label: '资金方',
      prop: 'capitalSideId',
      rules: [{ required: true, message: '请选择资金方', trigger: 'change' }],
      attrs: {
        clearable: true,
        placeholder: '请选择资金方',
        options: content.providertData,
        selectedField: ['keyId', 'capitalAndProductName']
        // disabled: content.isDisable
      },
      span: 24,
      tag: BaseSelect,
      on: {
        change (val) {
          content.changeCapitalSide(val)
        }
      }
    }
  ]
}
