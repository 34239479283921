<template>
  <div>
    <el-dialog
      title="选择业务单"
      :visible.sync="visibleState"
      width="35%"
      class="backForm"
      :close-on-click-modal='false'
      center
    >
      <base-form
        :componentList="fundForm"
        :showBtns="false"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '120px'
        }"
        class="formStyle"
        ref="addfromdata"
      />
      <span slot="footer" class="dialog-footer">
        <base-button label="确 认" @click="submit" />
        <base-button
          label="关 闭"
          type="default"
          @click="visibleState = false"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Storage from '@/utils/storage'
// import { progressApi } from '@/api/businessApi'
import { fundForm } from '../utils/config'
import { parameterApi } from '@/api/parameterApi'
import { replenishApi } from '@/api/businessApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { BaseButton, BaseForm },
  props: {
    visible: Boolean
  },
  data () {
    return {
      providertData: [],
      options: [],
      addQueryParas: {},
      isDisable: true, // 控制禁用
      businessOption: [] // 业务编号数据
    }
  },
  // 计算属性 类似于data概念
  computed: {
    fundForm () {
      return fundForm(this)
    },
    api () {
      return parameterApi
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visible (val) {
      if (val) {
        this.isDisable = true
        this.addQueryParas = {}
        this.providertInfo()
        this.$nextTick(() => {
          this.$refs.addfromdata.clearValidate()
        })
      }
    }
  },
  // 方法集合
  methods: {
    // 改变供应商
    changeGys (data) {
      this.$set(this.addQueryParas, 'businessNo', '')
      this.$set(this.addQueryParas, 'capitalSideId', '')
      this.$set(this.addQueryParas, 'capitalSideProductId', '')
      this.isDisable = true
      if (data) {
        replenishApi.queryServiceOrderNumber({ gysId: data }).then(res => {
          this.businessOption = res.data
          this.$refs.addfromdata.clearValidate()
        })
      }
    },
    // 改变业务编号
    changeBusinessNo (data) {
      if (data) {
        const [obj] = this.businessOption.filter(item => item.businessNo === data)
        if (obj && obj.capitalSideId) {
          this.isDisable = true
          this.$set(this.addQueryParas, 'capitalSideId', obj.capitalSideId)
          this.$set(this.addQueryParas, 'capitalSideProductId', obj.capitalSideProductId)
        } else {
          this.$set(this.addQueryParas, 'capitalSideId', '')
          this.$set(this.addQueryParas, 'capitalSideProductId', '')
          this.isDisable = false
        }
      } else {
        this.$set(this.addQueryParas, 'capitalSideId', '')
        this.$set(this.addQueryParas, 'capitalSideProductId', '')

        this.isDisable = true
      }
    },
    // 改变资金方
    changeCapitalSide (data) {
      if (data) {
        const [obj] = this.providertData.filter(item => item.keyId === data)
        this.$set(this.addQueryParas, 'capitalSideId', obj.keyId)
        this.$set(this.addQueryParas, 'capitalSideProductId', obj.capitalSideProductId)
      } else {
        this.$set(this.addQueryParas, 'capitalSideId', '')
        this.$set(this.addQueryParas, 'capitalSideProductId', '')
      }
    },
    handleClose () {
      this.visibleState = false
    },
    // 获取资金方信息
    providertInfo () {
      const params = {
        replyStatus: 1 // 审核状态
      }
      parameterApi.getFundsprovidertInfo(params)
        .then(res => {
          if (res.data) {
            this.providertData = res.data
            this.providertData.forEach(ele => {
              this.$set(ele, 'capitalAndProductName', ele.capitalSideName + '-' + ele.productName)
            })
          }
        })
    },
    // 提交
    submit () {
      this.$refs.addfromdata.validate(valid => {
        if (valid) {
          replenishApi.saveReview(this.addQueryParas).then(res => {
            if (res.data) {
              this.success('提交成功')
              this.visibleState = false
              this.$parent.handleFilter()
            } else {
              this.warning('提交失败')
            }
          })
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.backForm {
  height: 100vh;
  overflow: hidden;
  /deep/.el-dialog {
    height: 400px;
    margin-top: 30vh !important ;
  }
  /deep/ .el-dialog__body {
    height: calc(100% - 160px);
  }
  /deep/ .el-dialog__header {
    background: #4a7cf0;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
}
</style>
